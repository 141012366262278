import React from 'react';
import moment from 'moment';
import FormatNames from '../../utils/formatNames.util';

import './feedEntry.css';

export default function FeedEntry(props) {
	const { data, config } = props;
	const { styles } = config;
	const { timeGiven, kudo } = data;

	const imageIcon = kudo && (
		<div className="kl-feedEntry-iconContainer">
			<img className="kl-feedEntry-icon" src={kudo.src} alt={kudo.name} />
		</div>
	);

	return (
		<div className="kl-feedEntry-container" style={{ color: styles.textColor }}>
			{renderTimeGiven(timeGiven)}
			{renderTextPortion(props, timeGiven)}
			{imageIcon}
		</div>
	);
}

function renderTextPortion(props, timeGiven) {
	const { menuItem } = props;
	const { giver, kudo, receiver } = props.data;
	const { component } = menuItem;

	const isMyKudos = component === 'myKudos';
	const myKudosReceiver = isMyKudos && FormatNames('You');

	const giverComponent = Object.keys(giver).length
		? FormatNames(`${giver.firstName} ${giver.lastName}`)
		: FormatNames('Someone');
	const receiverTempComp = Object.keys(receiver).length
		? FormatNames(`${receiver.firstName} ${receiver.lastName}`)
		: FormatNames('Someone');
	const proposition = `gave ${kudo.name} to`;
	const receiverComponent = myKudosReceiver || receiverTempComp;

	if (!timeGiven) return '';
	else {
		return (
			<div className="kl-feedEntry-nameContainer">
				{giverComponent}
				<span className="kl-feedEntry-proposition">{proposition}</span>
				{receiverComponent}
			</div>
		);
	}
}

function renderTimeGiven(timeGiven) {
	if (timeGiven) {
		const date = new Date(timeGiven);

		const refDate = new Date();
		const refDateInMS = refDate.getTime();
		const renderTime = moment(date).format('h:mm a');
		const timeToRender = moment(date).calendar(refDateInMS, {
			sameDay: `[${renderTime}]`,
			lastWeek: '[Last] ddd h:mm a',
		});

		return <div className="kl-feedEntry-time">{`${timeToRender}`}</div>;
	}
	return '';
}
