import React from 'react';
// import { Enums } from '../../constants';
import Feed from '../Feed';

import './stats.css';

export default function Stats(props) {
	const { data, filter } = props;
	if (!data || !data.feed || !data.feed.length) return '';

	const feed = filterFeed(data, filter);
	const toRender = <Feed {...props} data={feed} />;

	return <div id="kl-stats-container">{toRender}</div>;
}

function filterFeed(data, filter) {
	let feed = data;
	if (filter && Object.keys(filter).length === 1) {
		const key = Object.keys(filter)[0];
		feed = data.feed.filter(entry => {
			return entry.kudo[key] === filter[key] ? true : false;
		});
		feed = { feed };
	}
	return feed;
}
