import React from 'react';
import ReplaceHyphenWithNonBreaking from './nonBreakingHyphen.util';

/**
 * All name formatting logic should be here
 * Returns formatted name with span wrapper
 * @param {string} name
 */
const FormatNames = name => {
	const hyphenFormattedName = ReplaceHyphenWithNonBreaking(name);

	const finalFormattedName = hyphenFormattedName;
	return <span className="kl-feedEntry-name">{finalFormattedName}</span>;
};

export default FormatNames;
