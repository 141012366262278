import React from 'react';
import FetchData from './utils/fetchData.util';
import { Enums } from './constants';
import { MenuItem } from './definitions';

import ToggleMenu from './components/ToggleMenu';
import Selector from './components/Selector';

import './App.css';

class App extends React.Component {
	state = {
		background: '',
		kudosCount: [],
		config: {},
		menuItem: new MenuItem(Enums.toggleMenuTitle.feed),
		bodyFont: '',
		menuFont: '',
		color: 'black',
	};

	componentDidMount = async () => {
		await this.fetchConfig();
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (prevState.config !== this.state.config) {
			this.setConfig();
		}
	};

	fetchConfig = async () => {
		const configObj = await FetchData('/api/meta', true);
		if (configObj) {
			const { config } = configObj;
			this.setState({ config, menuItem: config.pages.feed });
			return config;
		} else {
			setTimeout(() => this.fetchConfig(), 250);
		}
	};

	handleMenuClick = menuItem => {
		const { config } = this.state;
		this.setState({ menuItem }, () => {
			if (menuItem.background) this.setBackground(menuItem.background);
			else this.setBackground(config.styles.background);
		});
	};

	setBackground = background => {
		this.setState({ background });
	};

	setFonts = fonts => {
		if (fonts && Array.isArray(fonts)) {
			fonts.forEach((font, index) => {
				const fontNum = `${index + 1}`.padStart(2, '0');
				console.log(`Setting font${fontNum} from meta`);

				const linkTag = document.querySelector(`[key=font-link-${fontNum}]`);
				if (linkTag === null) console.log(`Not setting custom font${fontNum} from meta`);
				else if (font.importLink) {
					linkTag.setAttribute('href', font.importLink);
				}
			});
		} else console.log('Not setting custom fonts from meta');
	};

	setConfig = () => {
		console.log('Setting meta data');
		console.log(this.state.config);
		if (this.state.config) {
			const { pages, styles } = this.state.config;
			const { background, bodyFontUrl, menuItemFontUrl, textColor } = styles;

			if (background) this.setBackground(background);

			const getFontName = url =>
				url.includes('googleapis')
					? url.split('?family=')[1].split('&')[0].split('+').join(' ')
					: 'Open Sans';
			if (bodyFontUrl || menuItemFontUrl) {
				const fonts = [
					bodyFontUrl && { importLink: bodyFontUrl },
					menuItemFontUrl && { importLink: menuItemFontUrl },
				].filter(f => !!f);
				this.setFonts(fonts);
				this.setState({
					bodyFont: getFontName(bodyFontUrl),
					menuFont: getFontName(menuItemFontUrl),
				});
			}
			if (textColor) this.setState({ color: textColor });
			if (pages) {
				const menuItem = new MenuItem(pages.feed);
				this.handleMenuClick(menuItem);
			}
		}
	};

	render = () => {
		const { background, kudosCount, menuItem, config, bodyFont, menuFont, color } = this.state;

		const bgStyle = background
			? { background: `url(${background}) no-repeat fixed center / cover` }
			: { backgroundColor: 'white' };

		return (
			<div id="kl-main-container" style={{ ...bgStyle, fontFamily: bodyFont, color }}>
				<Header config={config} title={config && config.title} style={{ color }} />
				<ToggleMenu
					config={config}
					menuItem={menuItem}
					onClick={menuItem => this.handleMenuClick(menuItem)}
					menuFont={menuFont}
					color={color}
				/>
				<Selector config={config} badgeData={kudosCount} menuItem={menuItem} />
			</div>
		);
	};
}

export default App;

function Header(props) {
	return (
		<div id="kl-header-container">
			<h1 id="kl-header-text" style={props.style}>
				{props.title}
			</h1>
		</div>
	);
}
