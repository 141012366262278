import React from 'react';
import ReplaceSpaceWithNBSP from '../../utils/nbsp.util';
import { MenuItem } from '../../definitions';

import './toggleMenu.css';

export default function ToggleMenu(props) {
	return (
		<section id="kl-toggleMenu-section">
			<div id="kl-toggleMenu-container">{renderMenuItems(props)}</div>
		</section>
	);
}

function formatTitle(title) {
	let formatted = title;
	formatted = ReplaceSpaceWithNBSP(title);
	return formatted;
}

function handleClick(typedMenuItem, props) {
	const { onClick, menuItem } = props;
	const { component } = typedMenuItem;
	const notCurrentSelected = menuItem.component !== component;
	if (notCurrentSelected) onClick(typedMenuItem);
}

function renderMenuItems(props) {
	const { config } = props;
	let menuItems;

	if (config) {
		const { pages } = config;
		if (pages) {
			menuItems = Object.keys(pages)
				.filter(item => pages[item].included !== false)
				.map(item => {
					pages[item].component = item;
					return pages[item];
				});
		}
	}

	return (
		menuItems &&
		menuItems.map((menuItem, index) => {
			const typedMenuItem = new MenuItem(menuItem);
			return renderMenuItem(typedMenuItem, props, index);
		})
	);
}

function renderMenuItem(typedMenuItem, props, index) {
	const { menuFont, color, menuItem } = props;
	const { component, title } = typedMenuItem;
	const titleToRender = formatTitle(title || component);
	const className =
		menuItem.component === component
			? 'kl-toggleMenu-item-text selected'
			: 'kl-toggleMenu-item-text';

	return (
		<div
			className="kl-toggleMenu-item"
			onClick={() => handleClick(typedMenuItem, props)}
			style={{ color, borderColor: color, fontFamily: menuFont }}
			key={`toggleMenu-${index}`}
		>
			<div className="kl-toggleMenu-item-textContainer">
				<p className={className}>{titleToRender}</p>
			</div>
		</div>
	);
}
