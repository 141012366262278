import React from 'react';

import FeedEntry from '../FeedEntry';

import './feed.css';

export default function Feed(props) {
	const { data, filter, config, menuItem } = props;
	let feedData = data && data.feed;
	if (feedData && filter && filter.kudoID) {
		feedData = feedData.filter(record => {
			return record.kudo.kudoID === filter.kudoID;
		});
	}
	const toRender =
		feedData &&
		feedData
			.sort((a, b) => {
				if (a.timeGiven < b.timeGiven) return 1;
				else if (a.timeGiven > b.timeGiven) return -1;
				else return 0;
			})
			.map((item, index) => (
				<FeedEntry config={config} data={item} menuItem={menuItem} key={`KL-FeedEntry-${index}`} />
			));
	return <div id="kl-feed-container">{toRender}</div>;
}
