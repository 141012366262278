import React from 'react';

import './badge.css';

export default function Badge(props) {
	const { data, config, count, onClick, selected, menuItem } = props;

	let renderAllBadgeAndHasSrc;
	if (config && config.pages) {
		const page = config.pages[menuItem.component];
		renderAllBadgeAndHasSrc = page.kudos && page.kudos.allButtonUrl;
	}

	const { active, name, src } = determineSrcNameActive(
		data,
		config,
		renderAllBadgeAndHasSrc,
		menuItem
	);

	let badgeWrapperClass = 'kl-badge-wrapper';
	if (selected) badgeWrapperClass += ' kl-badge-selected';

	if (active) {
		return (
			<div className="kl-badge" onClick={() => handleOnClick(onClick, data.kudoID)}>
				<div className={badgeWrapperClass}>
					<img className="kl-badge-image" src={src} alt={name} />
				</div>
				{renderCount(count)}
			</div>
		);
	} else return '';
}

function determineSrcNameActive(data, config, renderAllBadgeAndHasSrc, menuItem) {
	let src = '',
		name = '',
		active = true;

	if (data.kudoID === 'all') {
		if (renderAllBadgeAndHasSrc) {
			if (config.pages) src = config.pages[menuItem.component].kudos.allButtonUrl;
			name = 'All Kudos';
		} else active = false;
	} else {
		src = data.kudo.src;
		name = data.kudo.name;
		active = data.kudo.active;
	}
	return { active, name, src };
}

function handleOnClick(onClick, kudoID) {
	onClick(kudoID);
}

function renderCount(count) {
	if (count !== undefined && count !== null) {
		return <p className="kl-badge-count">{count}</p>;
	}
}
