import React from 'react';

import './howTo.css';

export default function HowTo(props) {
	const { config } = props;

	if (config && config.pages) {
		const { instructions } = config.pages.howTo || {};
		if (instructions) {
			return (
				<div
					id="kl-howTo-container"
					className="kl-reactQuill-content"
					dangerouslySetInnerHTML={{ __html: instructions }}
				/>
			);
		}
	}
	return <div id="kl-howTo-container" />;
}
