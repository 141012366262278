const isProd = process.env.NODE_ENV === 'production';

const host = isProd ? 'https://kudos.bpdev.club' : 'http://localhost:8004';

const Config = {
	api: {
		host,
	},
};

export default Config;
