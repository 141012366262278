import React from 'react';
import Feed from '../Feed';

import './myKudos.css';

export default function MyKudos(props) {
	const { data, filter, config } = props;
	if (!data || !data.length) return '';
	const { feed, kudos } = data[0];

	let toRender = '';
	let feedData = feed;
	if (feed && kudos) {
		if (filter && Object.keys(filter).length === 1) {
			const key = Object.keys(filter)[0];
			feedData = feed.filter(entry => {
				return entry.kudo[key] === filter[key] ? true : false;
			});
		}
		toRender = <Feed {...props} data={{ feed: feedData }} />;
	}

	return (
		<div id="kl-myKudos-container">
			{renderMyKudosTitleOrNoneMsg(config, feedData)}
			{toRender}
		</div>
	);
}

function renderMyKudosTitleOrNoneMsg(config, feedData) {
	let myKudosMessage = 'My Kudos';
	if (config.pages) {
		const page = config.pages.myKudos;
		if (page.noKudosMessage) myKudosMessage = page.noKudosMessage;
	}

	const myKudosTitle = (
		<div id="kl-myKudos-titleContainer">
			<h1 id="kl-myKudos-title" style={{ color: config.styles.textColor }}>
				{myKudosMessage}
			</h1>
		</div>
	);

	return feedData ? '' : myKudosTitle;
}
