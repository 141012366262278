import React from 'react';
import FetchData from '../../utils/fetchData.util';

import Badges from '../Badges';
import Feed from '../Feed';
import HowTo from '../HowTo';
import Leaderboard from '../Leaderboard';
import MyKudos from '../MyKudos';
import Stats from '../Stats';

import './selector.css';

export default class Selector extends React.Component {
	state = { badgeData: {}, data: [], filter: {}, selBadge: 'all' };

	componentDidMount = async () => {
		const { menuItem } = this.props;
		await this.fetchData(menuItem.component);
	};

	componentDidUpdate = async (prevProps, prevState) => {
		if (prevProps.menuItem !== this.props.menuItem) {
			this.setState({ badgeData: {}, data: [] }, async () => {
				if (this.shouldFetchBadgeData()) await this.fetchBadgeData();
				await this.fetchData(this.props.menuItem.component);
			});
		}
	};

	fetchData = async component => {
		const isComponentAndNotHowTo = component !== 'howTo';
		if (isComponentAndNotHowTo) {
			const url = '/api/kudos/' + component;
			const data = await FetchData(url, true);
			if (data) this.setState({ data });
			else {
				setTimeout(() => this.fetchData(component, true), 250);
			}
		}
	};

	fetchBadgeData = async () => {
		const data = await FetchData('/api/kudos/kudosCount', true);
		if (data && Array.isArray(data) && data.length) {
			const { kudosCount } = data[0];
			this.setState({ badgeData: kudosCount });
		}
	};

	handleBadgeClick = kudoID => {
		if (kudoID) {
			const { selBadge } = this.state;
			if (kudoID === 'all' || selBadge === kudoID) {
				this.setState({ filter: {}, selBadge: 'all' });
			} else this.setState({ filter: { kudoID }, selBadge: kudoID });
		}
	};

	shouldFetchBadgeData = () => {
		const { config, menuItem } = this.props;
		if (config) {
			const { pages } = config;
			if (pages) {
				const page = config.pages[menuItem.component];
				const showKudos = page && page.kudos && Object.keys(page.kudos).length;
				return !!showKudos;
			}
		}
		return false;
	};

	render = () => {
		const { badgeData, data, filter, selBadge } = this.state;
		const { config, menuItem } = this.props;
		const { component } = menuItem;

		let badgeCount = {};
		let dataView = '';

		switch (component) {
			case 'feed':
				dataView = <Feed data={data} filter={filter} {...this.props} />;
				break;
			case 'howTo':
				dataView = <HowTo {...this.props} />;
				break;
			case 'leaderboard':
				dataView = <Leaderboard data={data} {...this.props} />;
				break;
			case 'myKudos':
				badgeCount = data && data.length ? data[0].kudos : {};
				dataView = <MyKudos data={data} filter={filter} {...this.props} />;
				break;
			case 'stats':
				dataView = <Stats data={data} filter={filter} {...this.props} />;
				break;
			default:
				break;
		}

		return (
			<div className="kl-selector-container">
				<Badges
					config={config}
					data={badgeData}
					menuItem={menuItem}
					badgeCount={badgeCount}
					selBadge={selBadge}
					onClick={this.handleBadgeClick}
				/>
				<div className="kl-selector-data">{dataView}</div>
			</div>
		);
	};
}
