import { Enums } from '../constants';
class MenuItem {
	static title = 'MenuItem';
	constructor(data) {
		if (typeof data === 'string' && Object.values(Enums.toggleMenuTitle).includes(data)) {
			this.title = data;
			this.component = data;
		} else {
			this.title = data.name;
			this.component = data.component;
			this.background = data.background;
		}
	}
}

export default MenuItem;
