import Config from '../config';
import HandleServerRes from './handleServerRes.util';

const assembleURL = (endpoint, withEventID) => {
	const { host } = Config.api;

	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');
	const eventID = urlParams.get('event') || 1;

	let search = '';
	if (token) search += `/?token=${token}`;

	const id = withEventID ? `/${eventID}` : '';

	return host + endpoint + id + search;
};

const FetchData = async (endpoint, withEventID) => {
	const headers = { 'content-type': 'application/json' };
	const url = assembleURL(endpoint, withEventID);
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers,
		});
		if (response) {
			const data = await response.json();
			HandleServerRes(data, url, 'GET');
			if (data.data) return data.data;
			else if (data.err) return data.err;
			else throw new Error('Got unexpected response from server');
		} else return null;
	} catch (error) {
		console.error('ERROR from FetchData', error);
		return null;
	}
};

export default FetchData;
