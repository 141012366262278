import React from 'react';
import Badge from '../Badge';

import './badges.css';

export default function Badges(props) {
	const { badgeCount, config, menuItem } = props;
	const show = shouldRenderBadges(config, menuItem, badgeCount);

	if (show) {
		const badges = renderBadges(props, show);
		return <div id="kl-badges-container">{badges}</div>;
	} else return '';
}

function renderBadges(props, show) {
	const { badgeCount, data, config, onClick, selBadge, menuItem } = props;

	if (!Object.keys(data).length) return '';
	else {
		const badges = data.map((badgeData, index) => {
			const showCount = typeof show === 'object' && show.withCount;
			let shouldRenderAllBadge;
			if (config.pages) {
				const page = config.pages[menuItem.component];
				const allButtonUrl = page.kudos && page.kudos.allButtonUrl;
				shouldRenderAllBadge = typeof show === 'object' && allButtonUrl;
			}
			let count = showCount && badgeCount && badgeCount[badgeData.kudoID];

			const thereIsNoCount = count === undefined || count === null;
			const isAllBadge = badgeData.kudoID === 'all';
			const isMyKudos = menuItem.component === 'myKudos';

			if (!shouldRenderAllBadge && isAllBadge) return '';

			if (showCount && thereIsNoCount) count = badgeData.total || 0;
			if (isAllBadge && isMyKudos) {
				count = 0;
				for (const key in badgeCount) {
					if (key !== 'all') count += badgeCount[key];
				}
			}

			const badgeProps = {
				data: badgeData,
				config,
				menuItem,
				count,
				onClick,
				selected: selBadge === badgeData.kudoID,
				key: `KL-badge-${index}`,
			};

			if (showCount) {
				if (count) return <Badge {...badgeProps} />;
				else return '';
			} else return <Badge {...badgeProps} />;
		});

		if (badges.length) return badges;
		else return '';
	}
}

function shouldRenderBadges(config, menuItem, badgeCount) {
	if (menuItem.component === 'myKudos') {
		if (!badgeCount || !Object.keys(badgeCount).length) return false;
	}
	if (config && config.pages) {
		const page = config.pages[menuItem.component];
		const showKudos = page && page.kudos && page.kudos.includeTotals;
		if (showKudos) return page.kudos;
		else return false;
	}
}
