/**
 * This needs to be kept in sync with the back end Enum values
 * server/constants/Enums
 */
export const Enums = {
	menuItemPosition: {
		left: 'left',
		middle: 'middle',
		right: 'right',
	},

	nbsp: '\u202F',
	nonBreakingHyphen: '\u2011',

	// check here
	toggleMenuTitle: {
		feed: 'Feed',
		howTo: 'How To',
		howTo2: 'HowTo',
		myKudos: 'My Kudos',
		myKudos2: 'MyKudos',
		myRencoin: 'My Rencoin',
		leaderboard: 'Leaderboard',
		region: 'Region',
		stats: 'Stats',
	},
};
