import React from 'react';
import './leaderboard.css';

export default function Leaderboard(props) {
	const { data, config } = props;
	return <div id="kl-leaderboard-container">{renderEntries(data, config)}</div>;
}

function renderEntries(data, config) {
	const { styles } = config;
	if (data && data.length) {
		return data.map((entry, index) => {
			const { total, attendee } = entry;
			return (
				<div className="kl-leaderboard-entryRow" key={`KL-Leaderboard-${index}`}>
					<div className="kl-leaderboard-entryContainer" style={{ color: styles.textColor }}>
						{renderTotal(total)}
						{renderName(attendee)}
					</div>
				</div>
			);
		});
	}
}

function renderTotal(total) {
	return <div className="kl-leaderboard-entryTotal">{total}</div>;
}

function renderName(attendee) {
	let renderName = 'Anonymous';
	if (attendee) {
		const { firstName, lastName } = attendee;
		const name = (firstName || '') + ' ' + (lastName || '');
		if (name) renderName = name;
	}

	return (
		<div className="kl-leaderboard-name">
			<p>{renderName}</p>
		</div>
	);
}
